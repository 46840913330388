import { FormSection } from '@@/form-components/FormSection';
import { SwitchField } from '@@/form-components/SwitchField';

export type DeployerOptions = {
  Prune: boolean;
};

export type DeployerOptionsFormValues = {
  Prune: boolean;
};

export type DeployerOptionsPayload = {
  Prune: boolean;
};

type OnValueChangeProps<T> = {
  value: T;
  onChange: (value: T) => void;
};

export const defaultDeployerOptionsFormValues: DeployerOptionsFormValues = {
  Prune: false,
};

type Props = {
  values: DeployerOptionsFormValues;
  onChange: (value: Partial<DeployerOptionsFormValues>) => void;
};

export function DeployerOptionsFieldset({ values, onChange }: Props) {
  return (
    <FormSection title="Deployer options">
      <PruneField
        value={values.Prune}
        onChange={(Prune) => onChange({ Prune })}
      />
    </FormSection>
  );
}

function PruneField({ value, onChange }: OnValueChangeProps<boolean>) {
  return (
    <div className="form-group">
      <div className="col-sm-12">
        <SwitchField
          checked={value}
          data-cy="edge-stack-deployer-option-prune"
          name="deployerOptionsPrune"
          label="Prune services"
          tooltip="When enabled, prune services that are no longer referenced in the stack file"
          labelClass="col-sm-3 col-lg-2"
          onChange={onChange}
        />
      </div>
    </div>
  );
}
